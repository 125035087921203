import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(site)": [24,[6]],
		"/(app)/account/settings": [7,[2,3]],
		"/(app)/account/settings/addresses": [8,[2,3]],
		"/(app)/account/settings/billing": [~9,[2,3]],
		"/(app)/account/settings/contacts": [10,[2,3]],
		"/(app)/account/settings/hospitals": [11,[2,3]],
		"/(app)/account/settings/images": [12,[2,3]],
		"/(app)/account/settings/notifications": [13,[2,3]],
		"/(app)/account/settings/transactions": [~14,[2,3]],
		"/(app)/admin": [15,[2,4]],
		"/(app)/admin/payments": [16,[2,4]],
		"/(app)/admin/users": [17,[2,4]],
		"/(app)/create/document": [18,[2]],
		"/(app)/doc/browse": [19,[2]],
		"/(doc)/doc/[id]": [23,[5]],
		"/(app)/greet": [20,[2]],
		"/(app)/kitchen-sink": [21,[2]],
		"/(site)/learn": [25,[6]],
		"/(app)/math": [22,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';